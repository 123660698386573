<style scoped>
.setupcontainer:hover {
    cursor: pointer;
}
</style>

<template>
    <v-container>

        <v-card class="pa-3">

            <div v-if="showSuccess" v-html="$t('labels.lightspeed.success')"></div>

            <div v-else>

                <div class="mb-3" v-if="myCompaniesInLightspeed && myCompaniesInLightspeed.length > 1">
                    <div class="display-1">{{$t('actions.lightspeed.selectLightspeedCompany')}}</div>
                    <v-select
                        :items="myCompaniesInLightspeed"
                        item-text="name"
                        item-value="id"
                        v-model="lightspeedCompany"
                    />
                </div>

                <div class="mb-3" v-if="brands && brands.length > 1">
                    <div class="display-1">{{$t('actions.lightspeed.selectBrand')}}</div>
                    <v-select
                        v-model="brandId"
                        :items="brands"
                        item-text="name"
                        item-value="id"
                    />
                </div>

                <div class="display-1">{{$t('actions.lightspeed.chooseSetupType')}}</div>

                    <p>{{$t('actions.lightspeed.chooseSetupTypeDescription')}}</p>

                   <div style="display: grid; justify-content: center; width:100%; grid-template-columns: repeat(auto-fill, 28em); grid-gap: 1em;">

                       <v-card outlined @click="setupType='static'" :style="staticStyle" class="pa-3 setupcontainer" style="text-align: center">
                            <div><b>{{$t('setupTypes.static.title')}}</b></div>

                            <img style="width:200px" class="mt-3 mb-3" src="@/assets/lightspeed-static.jpeg" />

                            <div v-html="$t('setupTypes.static.description')"></div>
                        </v-card>

                        <v-card outlined @click="setupType='dynamic'" :style="dynamicStyle" class="pa-3 setupcontainer" style="text-align: center">
                            <div><b>{{$t('setupTypes.dynamic.title')}}</b></div>

                            <img style="width:200px" class="mt-3 mb-3" src="@/assets/lightspeed-dynamic.jpeg" />

                            <div v-html="$t('setupTypes.dynamic.description')"></div>
                        </v-card>

                    </div>

                <v-btn class="mt-3" :disabled="!ready || loading" :loading="loading" color="primary" @click="confirm">{{$t('labels.confirm')}}</v-btn>

            </div>

        </v-card>

    </v-container>

</template>

<script>
import colors from '@/sass/colors.scss'
export default {
    data() {
        return {
            client_id: this.$env.VUE_APP_LIGHTSPEED_CLIENTID,
            code_verifier_localstorage_key: "lightspeed_code_verifier",
            myCompaniesInLightspeed: null,
            setupType: null,
            brands: null,
            lightspeedCompany: null,
            brandId: null,
            token: null,
            loading: false,
            showSuccess: false
        }
    },
    async mounted() {
        this.getBrands();
        this.token = await this.getAccessToken();
        this.myCompaniesInLightspeed = await this.$api.getMyCompaniesInLightspeed(this.token)
        if (this.myCompaniesInLightspeed.length == 1)
            this.lightspeedCompany = this.myCompaniesInLightspeed[0].id
    },

    computed: {
        ready() {
            return this.setupType && !!this.brands && this.brandId && !!this.myCompaniesInLightspeed && this.lightspeedCompany
        },

        dynamicStyle() {
            return { 
                "border-width": this.setupType == 'dynamic' ? "medium" : "thin",
                "border-color": this.setupType == 'dynamic' ? colors.bonsaiBlue : "unset" }
        },

        staticStyle() {
            return { 
                "border-width": this.setupType == 'static' ? "medium" : "thin",
                "border-color": this.setupType == 'static' ? colors.bonsaiBlue : "unset" }
        }
    },


    methods: {

        async getBrands() {
            this.loading = true
            const brands = await this.$api.getBrands()
            if (brands.length == 1) // Only when have 1 we set it, otherwise we push the user to select the correct one
                this.brandId = brands[0].id
            this.brands = brands
            this.loading = false
        },

        getAccessToken() {
            const queryParams = window.location.href.split('?')[1].split('&').map(p => ({key: p.split('=')[0], value: p.split('=')[1]}))
            const authorization_code = queryParams.find(p => p.key == 'code').value;
            return this.$api.fetchLightspeedToken(authorization_code)
        },

        async confirm () {
            let url = this.$env.VUE_APP_LIGHTSPEED_PAYMENT_URL
            this.loading = true

			if (this.setupType == "static")
				url += "?static"

            try {
                await this.$api.registerBonsaiInLightspeed(this.token, this.brandId, this.lightspeedCompany, url) 
                this.showSuccess = true
            } catch (err) {
                this.loading = false
            }

           
        }


    }
}
</script>